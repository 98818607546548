@import url('~app/styles/variables.css');

.latestReadme {
  flex: 1;
  position: relative;
}

.heading {
  font-size: var(--font-size-xl);
  width: 100%;
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.thumbnailContainer {
  padding-top: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);
  gap: var(--spacing-xs);
  overflow: hidden;

  @media (--mobile-device) {
    gap: var(--spacing-md);
  }

  @media (--small-viewport) {
    gap: var(--spacing-xs);
  }
}

.thumb {
  flex-basis: 48%;
  transition: transform var(--easing-fast);

  &:hover {
    transform: scale(1.05);
  }
}
