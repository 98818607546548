@import url('~app/styles/variables.css');

.wrapper {
  position: fixed;
  inset: 0;
  z-index: 200;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  background-color: var(--inverted-font-color);
  animation: fade-in var(--easing-fast) forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.content {
  width: var(--lego-max-width);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.inputContainer {
  margin: var(--development-banner-height) var(--spacing-md) 0;
  height: var(--lego-header-height);
  border-bottom: 1.5px solid var(--border-gray);
}

.inputContainer input {
  height: 100%;
  min-height: var(--lego-header-height);
  appearance: none;
  flex: 1;
  outline: none;
  border: 0;
  padding: 0 var(--spacing-md);
  font-size: 24px;
  color: var(--lego-font-color);
  background: transparent;

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.inputElementNormal {
  display: display;

  @media (--mobile-device) {
    display: none;
  }
}

.inputElementMobile {
  display: none;

  @media (--mobile-device) {
    display: block;
  }
}

.searchIcon {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--placeholder-color);
}

.searchResultItemIcon {
  color: var(--lego-font-color);
}

.sidePanel {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  flex-grow: 1;
  margin-bottom: var(--spacing-lg);
  padding: 0 var(--spacing-md);
}

.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--spacing-md);
  gap: var(--spacing-lg);
  overflow-y: auto;
  /* stylelint-disable indentation */
  background:
  /*
  Shadow cover TOP
  */
    linear-gradient(
        var(--inverted-font-color) 30%,
        rgba(var(--rgb-max), var(--rgb-max), var(--rgb-max), 0%)
      )
      center top,
    /*
  Shadow cover BOTTOM
  */
      linear-gradient(
        rgba(var(--rgb-max), var(--rgb-max), var(--rgb-max), 0%),
        var(--inverted-font-color) 70%
      )
      center bottom,
    /*
  Shadow TOP
  */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 15%),
        rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 0%)
      )
      center top,
    /*
  Shadow BOTTOM
  */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 15%),
        rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 0%)
      )
      center bottom;
  /* stylelint-enable indentation */
  background-repeat: no-repeat;
  background-size:
    100% 40px,
    100% 40px,
    100% 14px,
    100% 14px;
  background-attachment: local, local, scroll, scroll;

  .sidePanel {
    padding: 0;
  }
}

.quickLinksHeader {
  color: var(--lego-font-color);
}

.quickLinks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  list-style-type: none;
  gap: var(--spacing-xs);
  margin-left: calc(-1 * var(--spacing-md));
}

.quickLink {
  padding: var(--spacing-sm) var(--spacing-md);
  box-sizing: border-box;
  font-size: var(--font-size-lg);
  border-radius: var(--border-radius-md);
  transition: background-color var(--linear-fast);
  color: var(--lego-font-color);

  div {
    min-width: fit-content;
  }

  &:hover {
    background-color: var(--additive-background);
  }
}

.results {
  flex: 1 1 60%;
  min-width: 0;
}

.resultType {
  display: block;
  text-transform: capitalize;
}

.resultItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-gray);
  padding: var(--spacing-sm);
  height: 4rem;

  &:hover {
    background-color: var(--additive-background);
  }
}

.resultTitle {
  display: flex;
  min-width: 0;
  flex-direction: column;
  line-height: normal;
  color: var(--lego-font-color);
}

.resultDetail {
  color: var(--secondary-font-color);
  font-size: var(--font-size-sm);
  margin: 0;
}

.resultDetail > *:not(:last-child)::after {
  content: '\22C5';
  padding: 0 var(--spacing-xs);
}

.truncateTitle {
  display: block;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.isSelected {
  font-weight: 600;
}

.searchingText,
.quickLinksHeader {
  margin: var(--spacing-sm) 0;
}

.searchingText {
  text-align: center;
}
