.infoBubble {
  width: 330px;
  display: flex;
  flex-direction: column;
  text-align: center;
  word-wrap: break-word;
}

.bubble {
  margin: 0 auto 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 0;
  width: 70px;
  height: 70px;
  background-color: var(--lego-red-color);
  border-radius: 50%;
}

.data,
.meta {
  line-height: 1.3;
  margin: 0 auto;
}

.icon {
  color: var(--color-white);
  margin: 0 auto;
}

.iconLink {
  display: flex;
  flex: 1;
  align-items: center;
}

.data {
  order: 1;
  font-size: var(--font-size-lg);
  text-align: center;
  width: 100%;
}

.meta {
  order: 2;
  align-self: center;
  margin-top: 5px;
}

.smallBubble {
  margin: 0 auto 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: 0;
  width: 56px;
  height: 56px;
  background-color: var(--lego-red-color);
  border-radius: 50%;
}

.smallIcon {
  font-size: var(--font-size-lg);
  color: var(--color-white);
  margin: 0 auto;
}

.smallData {
  order: 1;
  line-height: 1.3;
  font-size: var(--font-size-sm);
  display: flex;
  justify-content: center;
}

.smallMeta {
  order: 2;
  font-size: var(--font-size-sm);
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
