@import url('~app/styles/variables.css');

.summary {
  white-space: pre;
  color: var(--secondary-font-color);
  margin: var(--spacing-sm) 0;

  > * {
    min-height: var(--spacing-lg);
  }
}

.registrationList {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
