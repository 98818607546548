@import url('~app/styles/variables.css');

.fixed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 72.5%;
  z-index: 2;
  bottom: var(--spacing-lg);
  margin: 0 auto;
  opacity: 0;
  transition: opacity var(--easing-medium);

  @media (--small-viewport) {
    width: 90%;
  }
}

.visible {
  opacity: 1;
}
