.grid {
  display: grid;
  grid-gap: 5px;
}

.registeredList {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 6% 12% 12% 14% 14% 17% 15%;
}

.registeredList > div {
  word-wrap: break-word;
}

.unregisteredList {
  display: grid;
  max-width: 500px;
  align-items: center;
  grid-template-columns: repeat(5, 20%);
}

.pill {
  margin: 0 auto;
  display: inline-block;
  text-transform: uppercase;
  padding: var(--spacing-xs) 0.375rem;
  font-size: var(--font-size-xs);
  font-weight: bold;
  border-radius: var(--border-radius-lg);
  letter-spacing: 0.6px;
  line-height: 1;
  box-shadow: var(--shadow-xs);
  align-items: center;
  align-self: center;
}

.webkomPill {
  background: linear-gradient(
    90deg,
    rgba(255, 0, 0, 100%) 0%,
    rgba(255, 154, 0, 100%) 10%,
    rgba(208, 222, 33, 100%) 20%,
    rgba(79, 220, 74, 100%) 30%,
    rgba(63, 218, 216, 100%) 40%,
    rgba(47, 201, 226, 100%) 50%,
    rgba(28, 127, 238, 100%) 60%,
    rgba(95, 21, 242, 100%) 70%,
    rgba(186, 12, 248, 100%) 80%,
    rgba(251, 7, 217, 100%) 90%,
    rgba(255, 0, 0, 100%) 100%
  );
  color: white;
}

.greenPill {
  background-color: var(--color-green-2);
  color: var(--color-green-7);
}

.orangePill {
  background-color: var(--color-orange-2);
  color: var(--color-orange-7);
}

.bluePill {
  background-color: var(--color-blue-2);
  color: var(--color-blue-6);
}

.questionIcon {
  color: var(--color-blue-6);
}

.consentIcon {
  font-size: 1.15em;
}

.center {
  flex: 1;
  justify-self: center;
}

.transparent {
  opacity: 0.7;
  color: var(--secondary-font-color);
}

.activeSuccess,
.activeEdit,
.activeInfo,
.activeDanger {
  border-radius: 50%;
}

.activeSuccess {
  background-color: var(--color-green-1);
}

.activeEdit {
  background-color: var(--color-orange-1);
}

.activeInfo {
  background-color: var(--color-blue-1);
}

.activeDanger {
  background-color: var(--color-red-1);
}

a.transparent:hover {
  opacity: 1;
}

.list {
  display: flex;
  width: 800px;
  flex-direction: column;
}

.grid > li {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-gray);
}

.consents {
  display: flex;
  justify-content: center;
}
