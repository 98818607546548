@import url('~app/styles/variables.css');

.field {
  width: 100%;
}

.inputWithError {
  border: 1.5px solid var(--danger-color);
  border-radius: var(--border-radius-md);
}

.fieldError {
  min-height: 35px;
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-top: var(--spacing-xs);
  background: var(--danger-color);
  color: var(--color-absolute-white);
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-md);
}
