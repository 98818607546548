.reaction {
  position: relative;
  height: 29px;
  width: 54px;
  user-select: none;
  border-radius: var(--border-radius-lg);
  background-color: var(--additive-background);
  border: 0 solid var(--color-red-4);
  color: var(--color-gray-6);
  margin: 2px;
  transition: var(--linear-fast);
  font-weight: 600;
  font-size: var(--font-size-sm);
}

.clickable {
  cursor: pointer;
}

.reacted {
  background-color: var(--color-red-1);
  color: var(--color-red-4);
  border-width: 1.5px;
}

.reactionCount {
  width: 0.9rem;
  text-align: center;
}
