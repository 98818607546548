@import url('~app/styles/variables.css');

.wrapper {
  display: grid;
  grid-gap: var(--spacing-xl);
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-template-areas: 'welcome welcome login' 'events events hsp' 'article article readme' 'links links links';
  padding: 0 var(--spacing-md);

  @media (--mobile-device) {
    grid-template-columns: 1fr;
    grid-template-areas: 'welcome' 'login' 'events' 'hsp' 'article' 'readme' 'links';
  }

  h1 {
    margin-top: 0;
  }

  h3 {
    margin-top: 0;
    margin-left: 0;
  }
}

.welcome i {
  display: inline;
}

.login {
  height: fit-content;
}

.links {
  grid-area: links;
}

.hsp {
  text-align: center;
}

.hspImage {
  width: 50%;
  height: auto;
  display: block;
  margin: auto;
}

.usefulLink {
  width: 325px;
  height: 400px;
  line-height: 1.3;
}

.usefulLinkHeader {
  height: 14%;
}

.usefulLinkDescription {
  color: var(--lego-font-color);
  height: 30%;
}

.usefulLinkGraphic {
  margin: 0 auto;
  width: auto;
  height: 40%;
}
