@import url('~app/styles/variables.css');

.container {
  margin: 0 var(--spacing-md) var(--spacing-md);
  line-height: 1.3;
}

.statistic {
  font-size: var(--font-size-xl);
  font-weight: bold;
}

@media (--mobile-device) {
  .container {
    margin: 0 var(--spacing-sm) var(--spacing-sm);
    flex-basis: 40%;
  }
}
