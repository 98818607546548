@import url('~app/styles/variables.css');

.section {
  @media (--medium-viewport) {
    flex-direction: column;
  }
}

.reverse {
  flex-direction: row-reverse;

  @media (--medium-viewport) {
    flex-direction: column-reverse;
  }
}
