@import url('~app/styles/variables.css');

.deleteFile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & i:hover {
    cursor: pointer;
  }

  & div {
    display: inline;
  }

  & span {
    max-width: 200px;
  }
}

.surveyContainer {
  display: flex;
  justify-content: center;
}
