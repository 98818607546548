@import url('~app/styles/variables.css');

.container {
  flex-basis: calc(100% / 3);
  margin: 0 var(--spacing-sm);
}

.text {
  margin-bottom: var(--spacing-lg);
}

@media (--mobile-device) {
  .container {
    flex-basis: 100%;
  }

  .boldTitle {
    text-align: center;
  }
}
