@import url('~app/styles/variables.css');

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex: 2;
  white-space: nowrap;
  margin-right: var(--spacing-xs);

  @media (--mobile-device) {
    display: none;
  }
}

.navigation a {
  font-size: var(--font-size-lg);
  padding: var(--spacing-sm) var(--spacing-md);
  height: 100%;
  color: var(--lego-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: color var(--linear-medium);
  white-space: nowrap;

  &:hover,
  &.activeItem {
    color: var(--color-gray-7);
  }
}

.navigation button {
  background-color: transparent;
  border: none;
}

.navbarDropdown {
  width: auto;
  padding: var(--spacing-sm);
  margin: 0;
}
