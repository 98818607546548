.reactionsContainer {
  position: relative;
}

.reactions {
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
}

.reactionPickerContainer {
  position: absolute;
  bottom: 100%;
  margin-bottom: var(--spacing-sm);
}

.addReactionEmojiContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
