.toggleIcon {
  visibility: visible;
  transition: color var(--linear-fast);
}

.moon {
  @media (--mobile-device) {
    color: var(--color-blue-8);
  }
}

.sun {
  @media (--mobile-device) {
    color: var(--color-orange-6);
  }
}

.moon:hover {
  color: var(--color-blue-8);
}

.sun:hover {
  color: var(--color-orange-6);
}
