.container {
  color: var(--color-gray-4);
  cursor: pointer;
}

.category:hover {
  opacity: 1;
  transition: color var(--easing-fast);
}

.isActiveCategory {
  color: var(--color-blue-5);
}

html[data-theme='dark'] .isActiveCategory {
  color: var(--color-blue-6);
}

.isSearching {
  transition: opacity var(--easing-fast);
  opacity: 0.4;
}
