@import url('~app/styles/variables.css');

:root {
  --toast-background: var(--lego-font-color);
}

.toastRegion {
  position: fixed;
  align-items: flex-start;
  bottom: var(--spacing-lg);
  left: var(--spacing-lg);
  z-index: 150;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  @media (--small-viewport) {
    bottom: var(--spacing-sm);
    left: var(--spacing-sm);
    right: var(--spacing-sm);
    gap: var(--spacing-sm);
  }
}

.toast {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  background-color: var(--toast-background);
  color: var(--inverted-font-color);
  font-size: var(--font-size-md);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  line-height: 20px;
  box-shadow: none;
  border: 1.5px solid var(--border-gray);

  @media (--small-viewport) {
    width: 100%;
  }

  &[data-animation='entering'] {
    animation: slide-in var(--bounce);
  }

  &[data-animation='queued'] {
    animation: slide-in var(--bounce);
  }

  &[data-animation='exiting'] {
    animation: fade-out var(--easing-slow);
  }
}

.defaultIcon svg {
  color: var(--inverted-font-color);
}

.success {
  background-color: var(--color-green-2);
  border-color: var(--color-green-7);
  color: var(--color-green-7);
}

.error {
  background-color: var(--color-red-1);
  border-color: var(--danger-color);
  color: var(--danger-color);
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
