.table {
  width: 100%;
}

.table thead {
  text-transform: capitalize;
}

/* Override the Checkbox alignment from flex-start to center */
.table td div {
  justify-content: center;
}

.table tr:nth-child(even) {
  background-color: rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 3%);
}
