.photoUploadStatus {
  z-index: 2;
  margin: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.close {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.header {
  width: 80%;
}
