@import url('~app/styles/variables.css');

.comment {
  padding: var(--spacing-md);
}

.username a {
  color: var(--lego-font-color);
  font-weight: 500;
}

.timestamp {
  font-size: var(--font-size-sm);
  color: var(--secondary-font-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timestampTooltip {
  width: fit-content;
}

.text {
  margin: var(--spacing-sm) 0;
  max-width: 90%;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

.anonymousProfilePicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--additive-background);
}
