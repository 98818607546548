@import url('~app/styles/variables.css');

.searchResult {
  padding-left: var(--spacing-md);
  border-left: 4px solid transparent;
  display: flex;
  justify-content: space-between;
}

.skeleton {
  height: 80px;
}

.searchResultTitle * {
  color: var(--lego-font-color);
}

.picture {
  flex: 1;
  justify-content: center;
}

.picture img {
  height: inherit;
  object-fit: contain;
  min-width: 100px;

  @media (--small-viewport) {
    width: 100%;
    object-fit: contain;
    margin: 0 10px 0 0;
    background-color: var(--color-white);
  }
}

.textbox {
  flex: 4;
  min-width: 260px;
  margin-right: var(--spacing-md);

  @media (--medium-viewport) {
    min-width: 400px;
  }

  @media (--small-viewport) {
    min-width: 260px;
  }
}
