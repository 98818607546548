@import url('~app/styles/variables.css');

.reactionPicker {
  composes: popover from '~app/styles/utilities.css';
  position: relative;
  height: 400px;
  width: 309px;
  line-height: 1.3;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 20;

  @media (--mobile-device) {
    max-width: 420px;
    width: 90vw;
  }
}

.emojiLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 60%;
  margin: 0 auto;
}
