@import url('~app/styles/variables.css');

.formHeaders {
  font-size: var(--font-size-lg);
}

.rowRec {
  @media (--medium-viewport) {
    gap: var(--spacing-sm) !important;
    flex-direction: column;
  }
}

.list {
  flex-direction: column-reverse;
}

.item {
  max-width: calc(var(--lego-max-width) / 2);
  padding: var(--spacing-md);
  margin-left: calc(-1 * var(--spacing-md));
  justify-content: space-between;
  line-height: 1.3;

  @media (--mobile-device) {
    max-width: 100%;
  }
}

.item:nth-child(even) {
  background-color: rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 2%);
  border-radius: var(--border-radius-md);
}

.date {
  font-weight: bold;
  text-decoration: underline;
}

.wrapperSendButton {
  align-items: center;
}

.recipients::after {
  content: ',\0000a0';
}

.recipients:first-child::before {
  content: '\0000a0';
}

.recipients:last-child::after {
  content: normal;
}
