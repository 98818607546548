.eventItem {
  min-height: 50px;
  padding: var(--spacing-sm) var(--spacing-md);
  border-left: 4px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-md);
  line-height: 1.3;
  border-top-right-radius: var(--border-radius-lg);
  border-bottom-right-radius: var(--border-radius-lg);
  color: var(--lego-font-color);
  transition: background-color var(--easing-fast);

  &:hover {
    background-color: var(--additive-background);
  }
}

.eventItemCompact {
  composes: eventItem;
  min-height: 156px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.time {
  color: var(--secondary-font-color);
}

.eventItemTitle {
  color: var(--lego-font-color);
  margin: 0;
  word-break: break-word;
}

.eventTime {
  color: var(--secondary-font-color);
}

.companyLogo {
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.companyLogoExtraCompact {
  composes: companyLogo;
  width: 90px;
}

.companyLogo img {
  min-width: 140px;
  max-height: 80px;
  object-fit: contain;
  background: white;
}

.companyLogoCompact img {
  min-width: 120px;
  max-height: 100%;
  object-fit: contain;
  background: white;
}

.companyLogoExtraCompact img {
  min-width: 90px;
  height: 30px;
  object-fit: contain;
  background: white;
}
