@import url('~app/styles/variables.css');

.checkbox {
  --background: inherit;
  --border: var(--border-gray);
  --border-hover: var(--color-gray-4);
  --border-active: var(--lego-red-color);
  --tick: white;

  position: relative;
}

.checkbox input,
.checkbox svg {
  width: 1.1rem;
  height: 1.1rem;
  display: block;
}

.label {
  user-select: none;
  line-height: 1.3;

  &:not(.disabled) {
    cursor: pointer;
  }
}

.checkbox input {
  appearance: none;
  position: relative;
  outline: none;
  background: var(--background);
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  transition: box-shadow var(--linear-medium);
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

  &:disabled {
    background-color: var(--additive-background);
    cursor: default;
  }
}

.checkbox input:hover:not(:disabled),
.checkbox input:focus:not(:disabled) {
  --s: 2px;
  --b: var(--border-hover);
}

.checkbox input:checked:not(:disabled) {
  --b: var(--border-active);
}

.checkbox svg {
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, var(--border-active));
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(var(--scale, 1)) translateZ(0);
}

.checkbox.path input:checked {
  --s: 2px;

  transition-delay: 0.2s;
}

.checkbox.path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition:
    stroke-dasharray var(--linear-slow),
    stroke-dashoffset var(--linear-slow);
}

.checkbox.bounce svg {
  --scale: 0;
}

.checkbox.path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}

.checkbox.bounce {
  --stroke: var(--tick);
}

.checkbox.bounce input:checked {
  --s: 11px;
}

.checkbox.bounce input:checked + svg {
  animation: bounce var(--linear-medium) forwards 0.2s;
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }

  75% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.fieldLabel {
  width: fit-content;
}
