.container {
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 90%;
  overflow-y: auto;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 40px);
  gap: 2.5px;
  padding: 5px;
  place-content: start space-around;
}

.emoji {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: background var(--easing-blazingly-fast);
}

.emoji:hover {
  background-color: rgba(255, 0, 0, var(--low-alpha));
}

.noEmojisFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
