@import url('~app/styles/variables.css');

.calendar th,
.calendar td {
  width: calc(100% / 7);
  text-align: center;
  font-size: var(--font-size-md);
  transition: background var(--easing-blazingly-fast);
}

.calendar td {
  padding: var(--spacing-xs) 0;
}

.dropdown {
  width: auto;
  max-width: 335px;
  padding: 0 var(--spacing-md) var(--spacing-md);
  z-index: 20;
}

.inputField {
  cursor: pointer;
}

.header {
  font-size: var(--font-size-lg);
  padding: var(--spacing-sm);
  text-transform: capitalize;
  text-align: center;
}

.calendarItem {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-sm);
  color: var(--lego-font-color);
  text-align: center;
  font-size: var(--font-size-md);
  padding: var(--spacing-sm);
  transition: background var(--easing-blazingly-fast);

  &:hover:not(.today, .selectedDate) {
    background: var(--additive-background);
  }
}

.prevOrNextMonth:not(.selectedDate) {
  opacity: 0.3;
}

.selectedDate {
  font-weight: 500;
  color: var(--color-white);
  background-color: var(--lego-font-color);
}

.today:not(.selectedDate) {
  color: var(--lego-font-color);
  background-color: var(--additive-background);
}

.rangeDropdown {
  width: auto;
  max-width: 600px;
  padding: 0 var(--spacing-md) var(--spacing-md);
  z-index: 20;

  @media (--small-viewport) {
    max-width: 315px;
  }
}

.inRange:not(.selectedDate) {
  background-color: var(--additive-background);
  border-radius: 0;
}

/* First selected date */
td:has(.selectedDate):has(+ td .inRange) .calendarItem {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Last selected date */
td:has(.inRange) + td:has(.selectedDate) .calendarItem {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
