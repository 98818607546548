@import url('~app/styles/variables.css');

.compactEvents {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  gap: var(--spacing-xl);

  @media (--medium-viewport) {
    flex-direction: column;
  }
}

@media (--mobile-device) {
  .compactLeft h3 {
    margin-top: 0;
  }
}

.compactLeft,
.compactRight {
  flex: 1;
}

.eventItem {
  height: 28.8px; /* Used for the skeleton component */
  color: var(--lego-font-color);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-left: calc(-1 * var(--spacing-sm));
  transition: background-color var(--easing-blazingly-fast);

  &:hover {
    background-color: var(--additive-background);
  }
}

.pinned {
  transform: rotate(-20deg);
  color: var(--lego-link-color);
}
