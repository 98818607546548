@import url('~app/styles/variables.css');

.frontpage {
  margin-top: 20px;
}

.frontpage > div {
  width: 100%;
}

.tagline {
  display: inline-flex;
  margin: 0;
  margin-left: var(--spacing-sm);
}

.overviewAuthor::after {
  color: var(--secondary-font-color);
  content: ' \2022';
}

.overview {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-flow: column wrap;
  justify-content: space-between;
}

.headline,
.normal {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  width: inherit;

  @media (--mobile-device) {
    margin: auto;
    justify-content: center;
    grid-template-columns: initial;
  }
}

.imageLink {
  width: 100%;
}

.imageLink img {
  background: white;
}

.normal {
  grid-template-columns: repeat(3, minmax(100px, 1fr));

  @media (--mobile-device) {
    grid-template-columns: initial;
  }
}

.item {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 60px;
  margin: 30px 0;
  padding: 0 20px;
}

.itemInfo {
  font-size: var(--font-size-sm);
  color: var(--secondary-font-color);
}

.item:not(:last-child, :nth-child(3n)) {
  border-right: 1px solid var(--border-gray);

  @media (--mobile-device) {
    border: none;
  }
}

.itemType {
  margin: 0;
  font-size: var(--font-size-md);
  line-height: 1.3;
}

.itemTitle {
  margin: 0;
  padding-top: 10px;
  line-height: 1.3;
}

.normal .itemTitle {
  font-size: var(--font-size-lg);
}

.itemTitle > a {
  color: rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 90%);
}

.itemDescription {
  padding-top: 10px;
  margin: 0;
  line-height: 1.3;
  color: var(--secondary-font-color);
}

.normal .itemDescription {
  font-size: var(--font-size-md);
}
