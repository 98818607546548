@import url('~app/styles/variables.css');

.sidebar {
  width: 15rem;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.pageList a {
  font-size: var(--font-size-md);
  color: var(--lego-font-color);
}

.dropdownBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-lg);
  font-weight: 500;
  color: var(--lego-font-color);
  margin-bottom: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  width: 100%;
}

.dropdownBtn:hover {
  background-color: rgba(255, 0, 0, var(--low-alpha));
}

.links {
  margin-left: var(--spacing-sm);
  width: 93%;
  border-radius: var(--border-radius-md);
}

.links:hover {
  background-color: rgba(255, 0, 0, var(--low-alpha));
}

.nonSelected {
  font-weight: normal;
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  border-radius: var(--border-radius-md);
}

.selected {
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
  border-radius: var(--border-radius-md);
  color: var(--color-absolute-white);
  background-color: var(--color-red-3);
}

.dropdownContainer {
  display: block;
  margin-bottom: var(--spacing-md);
}

.dropdownIcon {
  transition: var(--easing-medium);
}

@media (--mobile-device) {
  .dropdownBtn:hover {
    background-color: transparent;
  }
}
