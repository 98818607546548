@import url('~app/styles/variables.css');

.collapse {
  position: relative;
  overflow: hidden;
  transition: height var(--easing-medium);
}

.base {
  word-break: break-word;

  & div[data-type='todo'] > input {
    cursor: pointer;
    float: left;
    position: relative;
    top: 4px;
    left: -4px;
  }

  & ul {
    margin-left: var(--spacing-md);
  }

  & ol {
    margin-left: var(--spacing-md);
  }

  & figure {
    text-align: center;
    font-size: var(--font-size-sm);
  }
}

.showMore {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0%),
    var(--lego-card-color),
    var(--lego-card-color)
  );
  cursor: pointer;
}

.showMoreIcon {
  padding: 3px;
  margin: 0;
  border-radius: 50%;
  background-color: var(--lego-card-color);
}

.skeleton {
  height: 250px;
}
