@import url('./variables.css');

/**
 * Utility classes for use by other classes.
 *
 * Usage:
 * `composes: className from '~app/styles/utilities.css'`
 */

.container {
  max-width: var(--lego-max-width);
  margin: 0 auto;
}

.popover {
  border: 1px solid var(--border-gray);
  box-shadow: var(--shadow-sm);
}

.hiddenOnMobile {
  @media (--small-viewport) {
    display: none !important;
  }
}

.hiddenOnDesktop {
  display: none;

  @media (--small-viewport) {
    display: flex;
  }
}

:global {
  /* stylelint-disable-next-line selector-class-pattern */
  .u-small {
    font-size: var(--font-size-sm);
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .u-mb {
    margin-bottom: 15px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .u-ui-heading {
    color: var(--lego-font-color);
    font-size: var(--font-size-lg);
    font-weight: 500;
    line-height: 1.2;
    margin-top: var(--spacing-xl);
    margin-bottom: 0.8rem;

    @media (--mobile-device) {
      margin-top: 0;
      padding: 0;
      margin-bottom: 10px;
      margin-left: var(--spacing-sm);
    }

    @media (--small-viewport) {
      margin-top: var(--spacing-xl);
    }
  }

  .secondaryFontColor {
    color: var(--secondary-font-color);
  }
}
