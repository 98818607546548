.semesterStatus {
  width: 100%;
  height: 100%;
}

.semesterStatus button {
  width: 100%;
  height: 100%;
  padding: var(--spacing-sm);
}
