.pill {
  background-color: var(--additive-background);
  padding: 2px 10px;
  border-radius: var(--border-radius-sm);
  text-transform: uppercase;
  text-align: center;
  font-size: var(--font-size-sm);
  font-weight: 600;
  color: var(--lego-font-color);
}
