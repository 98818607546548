@import url('~app/styles/variables.css');

.notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  min-height: 200px;
  transition: height 3s ease-in-out;

  @media (--small-viewport) {
    width: 100%;

    &::before,
    &::after {
      display: none;
    }
  }
}

.maxHeight {
  max-height: 400px;
}

.notification {
  padding: 15px 15px 0;
  display: flex;
  flex-direction: row;
  color: var(--lego-font-color);
  line-height: 1.4;
  cursor: pointer;
}

.innerNotification {
  width: 100%;
  display: flex;
  flex-direction: row;
  word-break: break-word;
  white-space: pre-line;
}

.unRead {
  background-color: rgba(255, 0, 0, var(--low-alpha));
}

.icon {
  padding-top: 1px;
  padding-right: 15px;
}

.updatedAt {
  display: block;
  margin: 0;
  color: var(--secondary-font-color);
  font-size: var(--font-size-sm);
}
