@font-face {
  font-family: color-emoji;
  src: local('Noto Color Emoji');
  src: url('~app/assets/fonts/NotoColorEmoji.ttf');
}

.emoji {
  font-family: color-emoji, serif;
  width: 22px;
  height: 22px;
}
