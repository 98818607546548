@import url('./variables.css');

@font-face {
  font-family: 'OCR A Extended';
  src: url('../../app/assets/fonts/ocraext.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: var(--font-size-md);
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
}

body {
  line-height: 1.3;
  font-family: Inter, sans-serif;
  background: var(--lego-background-color);
  color: var(--lego-font-color);
  transition: color var(--linear-medium);
  position: relative;
}

a {
  color: var(--lego-link-color);
  cursor: pointer;
  text-decoration: none;
  transition: color var(--easing-blazingly-fast);
  line-height: 1.3;

  /* Wrap Links */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

p {
  margin-bottom: var(--spacing-sm);
  line-height: 1.3;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1 {
  font-size: var(--font-size-xl);
  margin: var(--spacing-md) 0;
}

h2,
h3 {
  margin: var(--spacing-sm) 0;
}

h1,
h2,
h3,
h4 {
  line-height: 1.3;
  font-weight: 600;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 22px;
}

h5 {
  line-height: 1.3;
  font-weight: 500;
}

i {
  display: inline-block;
}

table {
  line-height: 1.3;
  border-collapse: collapse;
}

th,
td {
  padding: var(--spacing-sm);
  display: table-cell;
}

th:first-child {
  border-radius: 2em 0 0 2em;
}

th:last-child {
  border-radius: 0 2em 2em 0;
}

input {
  @media (--mobile-device) {
    font-size: var(--font-size-md);
  }
}

label {
  display: block;
}
