.tipsList {
  font-size: var(--font-size-sm);
  margin-left: var(--spacing-xs);
  list-style: inside;
}

/* Remove labels in react-meter-bar component */
.removeLabels > div > div:first-child {
  display: none !important;
}

.removeLabels * {
  margin: 0;
}
