@import url('~app/styles/variables.css');

.surveyItem {
  padding-left: 20px;
  border-left: 4px solid transparent;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.surveryItemBorder {
  border-color: var(--color-orange-5);
}

.surveyItemTitle {
  color: var(--lego-font-color);
  margin: 0;
  word-break: break-word;
}

.surveyTime {
  color: var(--secondary-font-color);
}

.eventAndTimeFields {
  @media (--medium-viewport) {
    flex-direction: column;
  }
}

.search h2 {
  display: inline;
  margin-right: 20px;
  padding-top: 3px;
  margin-bottom: 40px;
}

.search input {
  width: 200px;
  height: 45px;
}

.companyLogo {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.companyLogo img {
  min-width: 120px;
  max-height: 80px;
  object-fit: contain;
  background: white;
}

.question {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
}

.left {
  width: 70%;
  order: 1;
}

.right {
  display: flex;
  order: 2;
  flex-direction: column;
  justify-content: space-between;
  min-width: 247px;
}

.questionType > div,
.reorderQuestion > div {
  padding: var(--spacing-sm);
}

.questionTitle {
  width: 100%;
}

.templateType {
  margin-bottom: 15px;
  margin-top: 2px;
}

.addQuestion {
  justify-content: center;
}

.dropdownOption {
  display: flex;
  align-items: center;
  margin: 3px 0;
  padding: 10px;
  border-radius: var(--border-radius-md);
  cursor: pointer;
}

.dropdownSelected {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.typeIcon {
  margin: 5px 0 0 20px;
}

.options li {
  margin: 5px 0 10px;
}

.option {
  &:hover {
    cursor: default;
  }
}

.optionField {
  width: calc(100% - 51px); /* 20px (margin) + 21px (icon width) = 51px */
  display: inline-block;
  margin: 0;
}

.optionInput {
  margin: 0 7px;
}

.templatePicker {
  position: relative;
}

.templateDropdown {
  color: transparent;
  position: absolute;
  bottom: 0;
  height: 0;
  width: 0;
}

.freeText {
  min-height: 100px;
}

/* stylelint-disable no-descending-specificity */

.staticSubmission > li:not(:first-child),
.detailQuestions > li:not(:first-child) {
  margin-top: var(--spacing-lg);
}

.staticSubmission .freeText {
  max-width: 600px;
}

.detailOptions > li {
  margin-top: 10px;
}

.mandatory {
  color: var(--danger-color);
}

.answers {
  margin: 0 0 40px 10px;
}

.answers > li {
  margin: 10px 0 20px;
}

.submissionNav {
  margin-bottom: 1rem;
}

.formOption {
  width: 18px;
}

.submissionOptions > div {
  margin-bottom: 10px;
}

.infoBubbles {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--mobile-device) {
    justify-content: space-around;
    align-items: center;
  }
}

.infoBubbles > div {
  margin: 30px 0;
  width: 150px;

  @media (--mobile-device) {
    margin-right: 20px;
  }
}

.eventSummary {
  margin: 1rem 0;
}

.questionResults {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -15px;

  @media (--mobile-device) {
    flex-direction: column;
    padding-left: 15px;
  }
}

.answerText {
  padding: 0.5rem;
  margin-left: -0.5rem;
  border-radius: var(--border-radius-md);
  line-height: 1.3;
}

.selectGraph {
  width: 200px;

  @media (--mobile-device) {
    margin: 0 auto;
  }
}

.graphContainer {
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;

  @media (--mobile-device) {
    flex-direction: column;
    padding-bottom: 15px;
  }
}

.average {
  @media (--mobile-device) {
    justify-content: center;
  }
}

.answerTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
  height: fit-content;
  border-radius: var(--border-radius-lg);
  color: var(--lego-font-color);
  transition: background-color var(--easing-fast);
  cursor: pointer;

  &:hover {
    background-color: var(--additive-background);
  }
}

.surveySkeleton {
  height: 70.2px;
}

.submissionSkeleton {
  height: 76.6px;
}

.textAnswerSkeleton {
  height: 36.8px;
}
