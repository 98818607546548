.youtubeFrame {
  aspect-ratio: auto;
  justify-content: center;
  transition: max-height var(--easing-fast);
}

.youtubeFrame iframe {
  width: 100vw;
  height: 56.25vw;
  max-width: 1100px;
  max-height: 619px;
}

.hidden {
  display: none;
}

.clicked {
  max-height: 619px;
}
