.title {
  width: 100%;
  padding: 2px 0;
}

.coverImage {
  width: 100%;
  height: 300px;
  overflow: hidden;
  background: var(--additive-background);
  margin-bottom: 20px;
  position: relative;
}
