@import url('~app/styles/variables.css');

.eventGroup {
  margin-bottom: var(--spacing-md);
}

.skeletonEventGroupTitle {
  width: 200px;
  min-height: 28.59px;
  margin: var(--spacing-sm) 0;
}

.bottomBorder {
  margin-top: var(--spacing-sm);
  border-top: 1px solid var(--border-gray);
}
