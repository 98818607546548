@import url('~app/styles/variables.css');

.gallery {
  width: 220px;
}

.galleryInfo {
  line-height: 1.3;
}

.galleryTitle {
  font-weight: bold;
}

.galleryDescription {
  font-size: var(--font-size-sm);
}

.descriptionRow {
  padding-bottom: 20px;
}

.galleryActions {
  display: inline-flex;
}

.galleryActions > button {
  margin-left: 10px;
}

.overlay {
  padding: var(--spacing-xs);
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.overlay:hover,
.overlaySelected {
  background: linear-gradient(
    rgba(255, 0, 0, calc(var(--low-alpha) + 35%)),
    rgba(255, 0, 0, var(--low-alpha))
  );
}

.overlay .icon {
  font-size: 32px;
  position: absolute;
}

.overlay .iconSelected {
  color: var(--success-color);
}

.createState {
  width: 100%;
  text-align: center;
}

.form {
  width: 100%;
}

.actionsContainer {
  height: 66px;
  margin-bottom: var(--spacing-md);
}

.infoOverlay {
  font-size: var(--font-size-sm);
  padding: 0 var(--spacing-xs);
}

.detailTitle {
  margin-bottom: 0;
  width: 100%;

  & h1 {
    margin-bottom: 0;
  }
}

.toggleButton {
  cursor: pointer;
}
