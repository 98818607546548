@import url('~app/styles/variables.css');

.galleryContainer {
  width: 100%;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-sm);
}

.galleryPhoto {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  cursor: pointer;
  position: relative;
}

.image {
  flex-grow: 1;
  object-fit: cover;
}

.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--color-white);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  display: inline-block;
}

.loadingIndicator,
.noPhotosWrapper {
  grid-column: span 3;
}

@media (--medium-viewport) {
  .gallery {
    grid-template-columns: 1fr 1fr;
  }

  .loadingIndicator,
  .noPhotosWrapper {
    grid-column: span 2;
  }
}

@media (--small-viewport) {
  .gallery {
    grid-template-columns: 1fr;
  }

  .loadingIndicator,
  .noPhotosWrapper {
    grid-column: span 1;
  }
}
