.timePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
}

.timePickerInput {
  display: flex;
  flex-direction: column;
  width: 40px;
}

.arrowUp,
.arrowDown {
  transition: transform var(--easing-medium);
}

.arrowUp:hover {
  transform: translateY(-3px);
}

.arrowDown:hover {
  transform: translateY(3px);
}
