.overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 50%);
  backdrop-filter: blur(5px);
}

.content {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 90%;
  background: var(--lego-card-color);
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
}

.handle {
  flex-shrink: 0;
}
