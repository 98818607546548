.input {
  font-size: var(--font-size-sm);
  font-family: Inter, sans-serif;
  border: 1.5px solid var(--border-gray);
  border-radius: var(--border-radius-md);
  color: var(--lego-font-color);
  background-color: inherit;
  overflow: hidden;

  &:focus-within {
    transition: border-color var(--easing-medium);
    border-color: var(--color-gray-4);
  }
}

.centered input {
  text-align: center;
}

.prefix {
  color: var(--placeholder-color);
}

.prefix,
.spacing:not(.centered) input {
  padding-left: var(--spacing-sm);
}

/* stylelint-disable no-descending-specificity */
.input input {
  width: 100%;
  height: 100%;
  background-color: inherit;
  border: none;
  outline: none;
  color: var(--lego-font-color);

  &:focus {
    border: none;
  }

  &::placeholder {
    color: var(--placeholder-color);
  }

  &:focus::placeholder {
    transition: opacity var(--easing-medium);
    opacity: 0;
  }
}

.textInput {
  height: 38px;
}

.disabled {
  opacity: 0.5;
}

span.suffix {
  display: flex;
  place-content: center center;
  flex-direction: column;
  width: fit-content;
  height: inherit;
  margin-left: auto;
  padding: 0 var(--spacing-sm);
  color: var(--placeholder-color);
  text-align: center;
  background-color: var(--additive-background);
  position: relative;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: var(--font-size-sm);
}

.removeBorder {
  border: none;
}

.phoneNumberInput {
  padding: var(--spacing-sm);
}

.togglePasswordVisibility {
  padding-right: var(--spacing-sm);
}
