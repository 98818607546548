:root {
  --tooltip-background: var(--lego-font-color);
}

.tooltip {
  animation: fade-in 0.2s;
  max-width: 300px;
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--tooltip-background);
  color: var(--inverted-font-color);
  border-radius: var(--border-radius-md);
  z-index: 1;
  font-size: var(--font-size-md);
  font-weight: normal;

  /* A small "border" added to the box-shadow 
  in case of sub-pixels rendering in the browser
  or rounding errors when positioning the arrow
  causing a small space between content and arrow */
  box-shadow:
    var(--shadow-md),
    0 0 0 0.5px var(--tooltip-background);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.arrow {
  animation: fade-in 0.2s;
}

.zIndex {
  z-index: 6;
}
