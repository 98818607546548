@import url('~app/styles/variables.css');

.addQuote {
  max-width: 550px;
}

/* Detail */

.quoteBottom {
  width: 100%;
  margin-top: var(--spacing-md);
  padding-bottom: var(--spacing-xs);
  margin-left: 130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  order: 3;

  @media (--small-viewport) {
    padding: 0 0 var(--spacing-lg);
    margin: var(--spacing-lg) 0 0;
  }
}

.singleQuote {
  padding-right: var(--spacing-md);
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--spacing-lg);

  &:last-child .quoteBottom {
    border-bottom: none;
  }
}

.leftQuote {
  display: flex;
  width: 100%;
  order: 1;
}

.leftQuote i {
  @media (--small-viewport) {
    display: none;
  }
}

.singleQuote h3 {
  font-weight: 300;
  font-size: 30px;
  margin: 0;
  line-height: 35px;
}

.theQuote {
  padding-left: 5px;
}

.theQuote a {
  color: var(--lego-font-color);

  &:hover {
    color: var(--color-orange-6);
  }
}

.quoteSource {
  font-size: var(--font-size-lg);
  order: 1;
  margin-top: -5px;
  width: 70%;
  line-height: 25px;
}

.quoteSource i {
  font-size: var(--font-size-lg) !important;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 140px;
  order: 2;
}

.bottomRight {
  order: 2;
  display: flex;
  flex-direction: row;
}

.quoteDate {
  color: var(--secondary-font-color);
  font-size: var(--font-size-sm);
  order: 1;
  margin-top: 3px;
}

.quoteAdmin {
  order: 2;
}

.quoteReactions {
  order: 4;
  margin-left: 125px;
}

@media (--small-viewport) {
  .sortQuote {
    margin: 0;
  }

  .singleQuote {
    width: 100%;
    flex-direction: column;
  }

  .leftQuote {
    width: 100%;
  }

  .quoteAdmin {
    margin: 0;
    width: 100%;
  }

  .quoteDate {
    margin-top: var(--spacing-sm);
  }

  .theQuote {
    width: 100%;
  }

  .quoteReactions {
    margin-left: 0;
  }
}

.innerPreview {
  width: 360px;
}
