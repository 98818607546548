@import url('~app/styles/variables.css');

.wrapper {
  overflow-x: auto;
  width: 100%;
  position: relative;
}

.tableContainer {
  width: 100%;
  position: relative;
}

.fadeOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(
    to bottom,
    transparent,
    var(--lego-card-color) 90%
  );
  pointer-events: none;
}

.wrapper table {
  width: 100%;
}

.wrapper thead {
  background-color: var(--color-gray-2);
  color: var(--color-gray-7);
  font-size: var(--font-size-sm);
  font-weight: 600;
}

/* https://stackoverflow.com/questions/36575846/how-to-make-div-fill-td-height-in-firefox */
.td {
  height: 1px;
}

@-moz-document url-prefix() {
  .td {
    height: 100%;
  }
}

.loader {
  height: 40.797px;
}

.wrapper tr:nth-child(even) {
  background-color: rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 3%);

  & .loader {
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--color-gray-1);
  }

  50% {
    background-color: var(--color-gray-2);
  }

  100% {
    background-color: var(--color-gray-3);
  }
}

.checkbox {
  width: auto;
  min-width: 150px;
  padding: var(--spacing-sm);
}

.checkbox > div,
.overlay > div {
  margin-bottom: var(--spacing-sm);
}

.checkbox a {
  font-size: var(--font-size-md);
}

.overlay {
  min-width: 200px;
  padding: var(--spacing-sm);
}

.icon,
.icon svg {
  color: var(--color-gray-7);
}

.iconActive {
  color: var(--lego-red-color);
}
