@import url('~app/styles/variables.css');

.reactionPickerFooter {
  width: 100%;
  height: 60px;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-md) 0;
  background-color: var(--lego-card-color);
  border-top: 1px solid var(--border-gray);
  border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
}
