@import url('~app/styles/variables.css');

.container {
  display: flex;
  align-items: center;
  padding: 0.3rem;
  flex-basis: calc(100% / 3);

  @media (--medium-viewport) {
    flex-basis: 50%;
  }

  @media (--small-viewport) {
    flex-basis: 100%;
  }
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: var(--spacing-sm);
}
