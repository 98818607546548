@import url('~app/styles/variables.css');

.wrapper {
  display: grid;
  grid-template:
    'compact-events registrations'
    'pinned weekly'
    'events poll'
    'events quote'
    'events readme'
    'events articles'
    'events .'
    / 2fr 1fr;
  padding: 0 var(--spacing-md);
  gap: var(--spacing-xl);

  @media (--mobile-device) {
    grid-template:
      'compact-events'
      'registrations'
      'pinned'
      'poll'
      'quote'
      'readme'
      'weekly'
      'articles'
      'events';
  }

  h3 {
    margin-top: 0;
    margin-left: 0;
  }
}

.compactEvents {
  grid-area: compact-events;
}

.registrations {
  grid-area: registrations;
}

.poll {
  grid-area: poll;
}

.quote {
  grid-area: quote;
}

.readMe {
  grid-area: readme;
}

.weekly {
  grid-area: weekly;
}

.articles {
  grid-area: articles;
}

.events {
  grid-area: events;
}

.showMore {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.meta {
  color: var(--secondary-font-color);
  line-height: 1.3;
}
