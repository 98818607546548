@import url('~app/styles/variables.css');

.chartLabel {
  line-height: 1.3;

  @media (--mobile-device) {
    align-items: center;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: var(--shadow-xs);
}

.tooltip {
  background-color: var(--lego-card-color);
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-md);
  border: 1.5px solid var(--border-gray);
}

.tooltipLabel {
  color: var(--secondary-font-color);
  line-height: 1.3;
}

.pieChartSkeleton {
  border-radius: 100%;
  margin: calc(calc(275px - 224px) / 2) calc(calc(300px - 224px) / 2);
}

.barChartSkeleton {
  border-radius: var(--border-radius-lg);
  margin: 20px 30px 10px 15px;
}
