.detailRow {
  color: var(--secondary-font-color);
}

span.detail + span.detail {
  margin-left: var(--spacing-sm);
}

span.detail + span.detail::before {
  margin-right: var(--spacing-sm);
  content: '•';
}

.smallDetails {
  font-size: var(--font-size-sm);
}
