@import url('~app/styles/variables.css');

.companyInfo {
  min-width: 180px;
  min-height: 21px;
}

.toggleEventsView {
  margin: var(--spacing-sm) 0;
}
