@import url('~app/styles/variables.css');

.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.rowSection {
  align-items: center;

  @media (--medium-viewport) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.rowSection > div {
  flex: 1;
  min-width: 0;

  @media (--medium-viewport) {
    width: 100%;
  }
}
