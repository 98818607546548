@import url('~app/styles/variables.css');

.link {
  color: var(--lego-font-color);
  width: 100%;
  height: 100%;
}

.body {
  display: flex;
  flex-direction: row;
  padding: 0;
  min-height: 115px;
}

.image {
  object-fit: cover;
  background: var(--color-white);
  height: 80px;
  width: 390px;

  @media (--mobile-device) {
    height: auto;
    width: 100%;
  }
}

.imageFrontpage {
  object-fit: cover;
  background: white;
  height: 80px;
  width: 270px;

  @media (--mobile-device) {
    height: auto;
    width: 100%;
  }
}

.right {
  text-align: center;
  padding: 20px;
  height: 100%;
  flex: 1;

  @media (--mobile-device) {
    border: none;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  @media (--mobile-device) {
    flex-direction: column;
  }
}

.left {
  text-align: center;
  font-size: var(--font-size-md);
  width: 390px;
  color: var(--secondary-font-color);

  @media (--mobile-device) {
    width: 100%;
    border: none;
  }
}

.leftFrontpage {
  text-align: center;
  font-size: var(--font-size-md);
  width: 272px;
  color: var(--secondary-font-color);

  @media (--mobile-device) {
    width: 100%;
    border: none;
  }
}

.info {
  text-align: center;
  padding: 3px 6px;

  @media (--mobile-device) {
    display: none;
  }
}

.itemTitle {
  margin: 0;
  min-height: 40px;
  font-size: var(--font-size-lg);
  word-break: break-word;
  overflow: hidden;

  @media (--mobile-device) {
    height: auto;
  }
}
