@import url('~app/styles/variables.css');

.emptyState {
  max-width: 280px;
  text-align: center;
  margin: 0 auto;
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  line-height: 1.3;

  @media (--mobile-device) {
    margin-top: var(--spacing-md);
    margin-bottom: var(--spacing-md);
  }
}
