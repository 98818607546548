@import url('~app/styles/variables.css');
@import url('../Event.module.css');

.star svg {
  color: var(--color-orange-6);
}

.eventType {
  height: 100%;
  display: flex;
  align-items: flex-end;

  strong {
    margin-bottom: calc(-1 * var(--spacing-sm));
  }

  @media (--small-viewport) {
    display: none;
  }
}

.tagRow {
  margin-top: var(--spacing-md);
}

.sidebarInfo {
  min-height: var(--spacing-lg);
}

.paymentInfo {
  margin-top: var(--spacing-sm);
}

.line {
  margin: var(--spacing-sm) 0;
  background-color: var(--border-gray);
  height: 1px;
}

.infoList tr {
  & td {
    width: unset;
  }

  &:last-of-type td {
    border-bottom: none;
  }
}

.mapButton {
  gap: var(--spacing-xs);
  font-size: var(--font-size-sm);
}

.mazemapImg {
  width: 30px;
  margin-left: calc(-1 * var(--spacing-xs));
}

.registrationMeta {
  margin-top: var(--spacing-sm);
}
