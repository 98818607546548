@import url('../Event.module.css');

.counter {
  text-align: center;
  font-size: var(--font-size-lg);
  flex-direction: column;
  color: var(--secondary-font-color);
}

.attendeeStatistics {
  font-size: var(--font-size-md);
  color: var(--secondary-font-color);
  padding-left: 15px;
  float: right;
}
