@import url('~app/styles/variables.css');

.base {
  position: relative;
  overflow: hidden;
}

.coverImage {
  width: 100%;
  aspect-ratio: 3.3;
  background: var(--additive-background);
}
