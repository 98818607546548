@import url('~app/styles/variables.css');
@import url('~app/styles/overlay.css');

.content {
  width: 250px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--border-gray);
}

.danger {
  & button {
    color: var(--danger-color);
  }

  &:hover {
    transition: background var(--easing-fast);
    background-color: var(--danger-color);

    & button {
      color: var(--inverted-font-color);
    }
  }
}

html[data-theme='dark'] .danger:hover button {
  color: var(--lego-font-color);
}

.active {
  background-color: var(--additive-background);
}
