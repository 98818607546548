@import url('~app/styles/variables.css');

.stripeButton {
  width: 100%;
}

.elementsFieldset {
  margin: var(--spacing-md) 0;
  padding: var(--spacing-md);
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-lg);
}

.elementsLegend {
  text-align: center;
  padding: 0 var(--spacing-md);
}

.formSeparator {
  display: flex;
  justify-content: center;
  margin: var(--spacing-sm) 0;
}

.stripeElement {
  display: block;
  margin: var(--spacing-sm) 0 var(--spacing-md);
  max-width: 500px;
  padding: var(--spacing-sm) var(--spacing-md);
  font-size: var(--font-size-md);
  font-family: 'Source Code Pro', monospace;
  border: 1.5px solid var(--border-gray);
  outline: 0;
  border-radius: var(--border-radius-md);
}

.error {
  background: var(--danger-color);
  color: var(--color-absolute-white);
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-bottom: var(--spacing-md);
  border-radius: var(--border-radius-sm);
}

.success {
  background: var(--success-color);
  color: var(--color-absolute-white);
  text-align: center;
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-top: var(--spacing-xs);
  border-radius: var(--border-radius-sm);
  transition: background var(--easing-slow);
}
