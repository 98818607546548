.cell {
  margin: 0 auto;

  /* 3.3 pixels are added to the minimum height of the outer grid */
  margin-bottom: 3.3px;
  background-color: var(--additive-background);
}

.skeletonCell {
  /* The same dimensions as the profile pictures  */
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
