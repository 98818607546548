@import url('../Event.module.css');

.editorSectionToggle {
  position: sticky;
  top: 0;
  background-color: var(--lego-card-color);
  z-index: 5;
  box-shadow: var(--shadow-bottom-md);
  cursor: default;
}

.toggleIcon {
  transition: var(--easing-medium);
}

.toggleIcon.expanded {
  transform: rotate(90deg);
}

.editorSectionToggle.collapsible {
  cursor: pointer;
}

.descriptionEditor {
  background: inherit;
  color: var(--lego-font-color);
}

.registrationDeadlineHours,
.unregistrationDeadlineHours {
  color: var(--secondary-font-color);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

.poolBox {
  border: 1.5px solid var(--border-gray);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-sm);
  margin-top: var(--spacing-sm);
}

.metaList {
  display: flex;
  align-items: center;
}

.metaList > ul {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}

.metaList > ul > li {
  flex-basis: 100%;
}

.metaList > ul > .poolBox {
  flex-basis: 32%;

  @media (--medium-viewport) {
    flex-basis: 49%;
  }

  @media (--small-viewport) {
    flex-basis: 100%;
  }
}

.metaList > span {
  display: flex;
}

.label {
  cursor: default;
  font-size: var(--font-size-lg);
}

.poolHeader {
  text-align: center;
}

.tagRow {
  margin-top: var(--spacing-md);
}

.addPoolButton {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-sm);
}

.centeredButton {
  display: flex;
  justify-content: center;
}

.subSection {
  /* Hacky spacing to get the subSection to align with the checkbox */
  margin-top: -2px;
  margin-left: 7px;
  border-left: var(--spacing-xs) solid var(--lego-red-color);
  padding: var(--spacing-sm) 0 0 var(--spacing-md);
}

.imageGallery {
  min-width: 600px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  @media (--small-viewport) {
    min-width: 100%;
  }
}

.imageGalleryEntry {
  width: 90%;
  cursor: pointer;
  border-radius: var(--border-radius-md);

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.65;
  }
}

.groupSuggestionWrapper {
  margin-bottom: var(--spacing-sm);

  > .groupSuggestion {
    cursor: pointer;
    font-size: var(--font-size-xs);

    &.selected {
      background-color: var(--lego-font-color);
      color: var(--inverted-font-color);
    }

    &.implicit {
      cursor: not-allowed;
      background-color: var(--color-gray-4);
      color: var(--inverted-font-color);
    }
  }
}
