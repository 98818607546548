@import url('~app/styles/variables.css');

.body {
  display: flex;
  text-align: center;
  line-height: 1.3;
  min-height: 140px;
  padding: 0;
  flex-grow: 1;
}

.link {
  display: flex;
  flex-direction: column;
  color: rgba(var(--rgb-min), var(--rgb-min), var(--rgb-min), 90%);
}

.image {
  object-fit: cover;
  width: 100%;
  height: auto;
  background: white;
}

.infoWrapper {
  display: flex;
  flex-grow: 1;
  padding: 15px 30px 20px;
  flex-direction: column;
  justify-content: space-around;
}

.metaWrapper {
  margin-bottom: var(--spacing-xs);
}

.articleTitle {
  margin: 0 0 var(--spacing-xs);
  font-size: var(--font-size-lg);
  word-break: break-word;
}

.articleMeta {
  margin: 0 5px;
  margin-bottom: 8px;
}

.articleDescription {
  color: var(--secondary-font-color);
}
